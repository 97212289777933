import {
  Button,
  Card,
  CardContent,
  CardTitle,
  MenuDropdown,
  MenuDropdownItem,
  Modal,
  TimeDistance,
} from '@energybox/react-ui-library/dist/components';
import {
  CircuitBreakerFromApiResponse,
  DeviceTypeDisplayText,
  DistributionPanel,
  EnergyDeviceFromApi,
  EnergyPro,
  EnergySensor,
  FirmwareGatewayModel,
  ObjectById,
} from '@energybox/react-ui-library/dist/types';
import {
  classNames,
  global,
  KW_UNIT,
  mapArrayToObject,
} from '@energybox/react-ui-library/dist/utils';
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../reducers';
import {
  EnergyProSensorReading,
  SubscribedEnergyPro,
} from '../../../reducers/subscribedEnergyPros';
import { getEnergyDeviceSensorsOfEnergyPro } from '../../../utils/energyPro';
import SelectActiveEnergyPro from '../SelectActiveEnergyPro';

import equals from 'ramda/src/equals';
import assocPath from 'ramda/src/assocPath';
import dissocPath from 'ramda/src/dissocPath';
import pipe from 'ramda/src/pipe';
import rPick from 'ramda/src/pick';
import pathOr from 'ramda/src/pathOr';

import styles from './DPSetUpTable.module.css';
import UpdateModal from '../UpdateModal';

import {
  Actions as EnergyProActions,
  destroy,
  getEnergyProsByDistributionPanelId,
  hideDeleteEnergyProModal,
  removeAllEnergyDevices,
  removeEnergyDeviceFromBus,
  requestEPro2Config,
  showDeleteEnergyProModal,
} from '../../../actions/energy_pros';
import {
  patch as patchCircuitBreaker,
  destroy as deleteBreaker,
} from '../../../actions/circuit_breakers';
import { editEnergyDeviceSensorPort } from '../../../actions/energy_devices';
import {
  EnergyPro as EnergyProIcon,
  WarningIcon,
} from '@energybox/react-ui-library/dist/icons';
import { EnergyPro2 as EnergyPro2Icon } from '@energybox/react-ui-library/dist/icons';
import { EntityInfoToSub } from '@energybox/react-ui-library/dist/types/StreamApi';
import { renderAPIerror } from '../../../utils/apiErrorFeedback';
import { patch as patchMainBreaker } from '../../../actions/main_breakers';
import UpdateFirmwareModal from '../../../components/UpdateFirmwareModal';
import { getMapFromArrayOneToOne } from '@energybox/react-ui-library/dist/utils/util';
import { MdMoreHoriz } from 'react-icons/md';
import EnergyProConfig from './EnergyProConfig';
import SensorBusTree from './SensorBusTree';
import NewEnergyDeviceSensorModal from '../../EnergyDevices/NewEnergyDeviceSensorModal';
import { IoMdRefresh } from 'react-icons/io';
import { default as EnergyProConfigGen2 } from './EnergyPro2/EnergyProConfig';
import { default as SensorBusTreeGen2 } from './EnergyPro2/SensorBusTree';

export enum UpdateEnergyDeviceAction {
  ENERGY_DEVICES_FROM_API = 'ENERGY_DEVICES_FROM_API',
  BREAKERS_FROM_API = 'BREAKERS_FROM_API',
  CT = 'ct',
  PORT = 'port',
  REVERSE_POLARITY = 'reversePolarity',
  PHASE = 'phase',
  BREAKER_ID = 'breakerId',
  BREAKER_POLE = 'breakerPole',
  BREAKER_EQUIPMENT_ID = 'equipmentId',
  SITE_TOTAL = 'siteTotal',
  DELETE_ENERGY_DEVICE = 'DELETE_ENERGY_DEVICE',
  DELETE_BREAKER = 'DELETE_BREAKER,',
}

export enum UpdateEntity {
  FROM_API,
  BREAKER,
  ENERGY_DEVICE,
  MAIN_BREAKER,
  DELETE_BREAKER,
}

export const acceptedSensorFieldKeys = [
  'breakerId',
  'breakerPole',
  'ct',
  // NOTE: for some reason
  // energyDevicePort and port refers to the same entity
  // but have different names
  'reversePolarity',
  'phase',
];

export const acceptedPatchBreakerFields = [
  'id',
  'breakerTitle',
  'description',
  'equipmentId',
  'rating',
  'breakerColumn',
  'breakerSlot',
  'siteTotal',
  'subpanel',
];

export type DPTableState = {
  [deviceId: number]: {
    [port: number]: DevicePortDisplayData;
  };
  breakersById: ObjectById<CircuitBreakerFromApiResponse>;
};

export type ActionPayLoad = {
  type: UpdateEnergyDeviceAction;
  id: number;
  deviceTitle: string;
  port?: number;
  value?;
  breakerId?: number;
  payload?: DevicePortDisplayData[];
  entity: UpdateEntity;
};

const getUpdateConfirmationText = (
  payload: ActionPayLoad,
  activeEnergyPro: EnergyPro
) => {
  const { type, deviceTitle } = payload;
  let entityTitle = <div></div>;
  if (deviceTitle) {
    entityTitle = deviceTitle ? (
      <span className={styles.bold}>{deviceTitle}</span>
    ) : (
      <>this bus device</>
    );
  } else {
    entityTitle = activeEnergyPro.title ? (
      <span className={styles.bold}>{activeEnergyPro.title}</span>
    ) : (
      <>this {DeviceTypeDisplayText[activeEnergyPro.model]}</>
    );
  }
  if (type === UpdateEnergyDeviceAction.DELETE_ENERGY_DEVICE) {
    return <span>Are you sure you want to delete {entityTitle}?</span>;
  }

  return (
    <span>
      Are you sure you want to update the configuration of {entityTitle}?
    </span>
  );
};

type Props = {
  siteId: number;
  breakerId?: number;
  distributionPanel?: DistributionPanel;
  energyPros: EnergyPro[];
  setActiveEnergyProsId: (id: number) => void;
  activeEnergyProId: number;
  activeEnergyPro: EnergyPro | undefined;
};

const reducer = (state: DPTableState, action: ActionPayLoad): DPTableState => {
  switch (action.type) {
    case UpdateEnergyDeviceAction.DELETE_ENERGY_DEVICE:
      return dissocPath([action.id], state);

    case UpdateEnergyDeviceAction.ENERGY_DEVICES_FROM_API:
      if (action.payload === undefined) return state;

      let updatedState = { ...state };
      action.payload.forEach(sensorData => {
        const { energyDeviceId, port } = sensorData;
        updatedState = assocPath(
          [energyDeviceId, port],
          sensorData,
          updatedState
        );
      });

      return updatedState;

    case UpdateEnergyDeviceAction.BREAKERS_FROM_API:
      return { ...state, breakersById: action.value || {} };

    case UpdateEnergyDeviceAction.SITE_TOTAL:
      if (action.breakerId === undefined) return state;
      return assocPath(
        ['breakersById', action.breakerId, 'siteTotal'],
        action.value,
        state
      );
    case UpdateEnergyDeviceAction.BREAKER_EQUIPMENT_ID:
    case UpdateEnergyDeviceAction.PORT:
    case UpdateEnergyDeviceAction.CT:
    case UpdateEnergyDeviceAction.REVERSE_POLARITY:
    case UpdateEnergyDeviceAction.PHASE:
    case UpdateEnergyDeviceAction.BREAKER_ID:
    case UpdateEnergyDeviceAction.BREAKER_POLE:
      if (action.port === undefined) return state;
      return assocPath(
        [action.id, action.port, action.type],
        action.value,
        state
      );

    case UpdateEnergyDeviceAction.DELETE_BREAKER:
      if (action.breakerId === undefined) return state;
      return pipe(
        dissocPath(['breakersById', action.breakerId]),
        dissocPath([action.id, action.port])
      )(state);

    default:
      throw new Error();
  }
};

const initialState: DPTableState = {
  breakersById: {},
};

const DPSetUpTable = ({
  distributionPanel,
  siteId,
  energyPros,
  activeEnergyPro,
  setActiveEnergyProsId,
}: Props) => {
  /* Local states */
  const [showDeleteAllDevicesModal, setShowDeleteAllDevicesModal] = useState(
    false
  );
  const [showUpdateFirmwareModal, setShowUpdateFirmwareModal] = useState(false);
  const [unconfirmedUpdateAction, setUnconfirmedUpdateAction] = useState<
    ActionPayLoad | undefined
  >(undefined);
  const [activeOnly, setActiveOnly] = useState(false);
  const [timerRunning, setTimerRunning] = useState(false);

  /* Redux */
  const reduxDispatch = useDispatch();
  const isEpro2sLoading = useSelector(({ energyPros }: ApplicationState) => {
    return energyPros.loadingStatusByAction[
      EnergyProActions.GET_ENERGY_PROS_BY_DISTRIBUTION_PANEL_ID_LOADING
    ];
  });
  const showingDeleteEnergyProModal = useSelector(
    ({ energyPros }: ApplicationState) => {
      return activeEnergyPro
        ? energyPros.editById[activeEnergyPro.id].showDeleteEnergyProModal
        : false;
    }
  );

  const subscribedEnergyPro = useSelector<
    ApplicationState,
    SubscribedEnergyPro
  >(({ subscribedEnergyPros }) => {
    return pathOr('', [activeEnergyPro?.id], subscribedEnergyPros.byId);
  }, equals);

  ///*** useMemo values ***///
  const energySensors: EnergySensor[] = useSensorsFromEnergyPro(
    activeEnergyPro
  );

  const [localStore, localDispatch] = useReducer(reducer, initialState);
  const [newSensorDevice, setNewSensorDevice] = useState<
    EnergyDeviceFromApi | EnergyPro | undefined
  >(undefined);

  const showingNewEnergyDeviceSensorModal = useSelector(
    ({ energyDevices }: ApplicationState) => {
      return energyDevices.showNewEnergyDeviceSensorModal;
    }
  );

  useEffect(() => {
    let interval: any = null;
    if (timerRunning && distributionPanel) {
      interval = setInterval(() => {
        reduxDispatch(getEnergyProsByDistributionPanelId(distributionPanel.id));
        setTimerRunning(false);
      }, 10000);
    } else if (!timerRunning) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timerRunning]);

  useEffect(() => {
    if (!activeEnergyPro) return;
    const ports = energySensors.map(energySensor => {
      const breaker = energySensor?.breaker;
      const { _entity } = breaker || {};
      const isMainBreaker = _entity === 'MainBreaker';
      return {
        ...energySensor,
        energySensorId: energySensor.id,
        isMainBreaker,
      };
    });

    localDispatch({
      type: UpdateEnergyDeviceAction.ENERGY_DEVICES_FROM_API,
      deviceTitle: '',
      id: activeEnergyPro.id,
      payload: ports,
      entity: UpdateEntity.FROM_API,
    });
  }, [activeEnergyPro, energySensors]);

  useEffect(() => {
    if (!distributionPanel) return;
    const { breakers: breakersInPanelData } = distributionPanel;
    const rawBreakers = breakersInPanelData.map(({ breaker }) => {
      return breaker;
    });
    localDispatch({
      type: UpdateEnergyDeviceAction.BREAKERS_FROM_API,
      deviceTitle: '',
      // replace dummy id with proper undefined typing/logic
      id: 1,
      value: getMapFromArrayOneToOne(rawBreakers),
      entity: UpdateEntity.FROM_API,
    });
  }, [distributionPanel]);

  const onUpdateConfirm = useCallback(() => {
    if (!unconfirmedUpdateAction || !activeEnergyPro) return;
    const panelId = activeEnergyPro.distributionPanelId;
    const {
      id,
      port,
      type,
      value,
      entity,
      breakerId,
    } = unconfirmedUpdateAction;
    if (type === UpdateEnergyDeviceAction.DELETE_ENERGY_DEVICE) {
      if (!value) return;
      reduxDispatch(
        removeEnergyDeviceFromBus(activeEnergyPro.id, id, value.busNumber)
      );
      localDispatch(unconfirmedUpdateAction);
      setUnconfirmedUpdateAction(undefined);
      return;
    }
    if (!port) return;
    const portData = localStore[id][port];
    if (!portData) return;
    switch (entity) {
      case UpdateEntity.ENERGY_DEVICE: {
        const payload = {
          ...rPick(acceptedSensorFieldKeys, portData),
          energyDevicePort: port,
          // update the API before updating the local state
          // this is consistent with DP live reading table
          // no loading state while these values are being updated
          [type]: value,
        };
        reduxDispatch(editEnergyDeviceSensorPort(id, port, payload));
        localDispatch(unconfirmedUpdateAction);
        break;
      }
      case UpdateEntity.BREAKER: {
        const payload = {
          ...rPick(acceptedPatchBreakerFields, portData),
          id:
            type === UpdateEnergyDeviceAction.BREAKER_ID
              ? value
              : portData.breakerId,
          // update the API before updating the local state
          // this is consistent with DP live reading table
          // no loading state while these values are being updated
          [type]: value,
        };
        // rename object key breakerId into id.
        delete payload['breakerId'];
        reduxDispatch(patchCircuitBreaker(panelId, payload.id, payload));
        localDispatch(unconfirmedUpdateAction);
        break;
      }
      case UpdateEntity.DELETE_BREAKER: {
        if (breakerId === undefined) break;
        reduxDispatch(deleteBreaker(panelId, breakerId));
        localDispatch(unconfirmedUpdateAction);
        break;
      }
      case UpdateEntity.MAIN_BREAKER: {
        if (!breakerId || !distributionPanel) break;
        const { mainBreaker } = distributionPanel;
        const payload = {
          [type]: value,
        };
        reduxDispatch(patchMainBreaker(mainBreaker.id, panelId, payload));
        break;
      }
      default:
        break;
    }

    setUnconfirmedUpdateAction(undefined);
  }, [localStore, unconfirmedUpdateAction, activeEnergyPro, distributionPanel]);

  const energyProSubscriptionInfo:
    | EntityInfoToSub[]
    | undefined = useMemo(() => {
    if (!activeEnergyPro) return undefined;
    const { id, uuid, vendor } = activeEnergyPro;
    return [
      {
        id,
        uuid,
        vendor,
      },
    ];
  }, [activeEnergyPro]);

  const bus1RootDevice = activeEnergyPro?.bus1Device;
  const bus2RootDevice = activeEnergyPro?.bus2Device;

  const [bus1Nodes, bus1DeviceById] = useMemo(() => {
    const nodes: EnergyDeviceFromApi[] = [];

    buildSensorBusNodes(bus1RootDevice, nodes);
    const deviceById: ObjectById<EnergyDeviceFromApi> = mapArrayToObject(nodes);
    // remove the root node
    // root is being rendered separately
    return [nodes, deviceById];
  }, [bus1RootDevice]);

  const [bus2Nodes, bus2DeviceById] = useMemo(() => {
    const nodes: EnergyDeviceFromApi[] = [];

    buildSensorBusNodes(bus2RootDevice, nodes);
    const deviceById: ObjectById<EnergyDeviceFromApi> = mapArrayToObject(nodes);
    // remove the root node
    // root is being rendered separately
    return [nodes, deviceById];
  }, [bus2RootDevice]);

  // if bus1 has an odd number of devices
  // highlight the first row of bus2
  const highlightBus2FirstRow = bus1Nodes.length % 2 !== 0;

  if (!activeEnergyPro || !distributionPanel) return null;

  const isEnergyPro2 = activeEnergyPro.model === 'ENERGYPRO2';
  const eproDisplayText =
    DeviceTypeDisplayText[activeEnergyPro.model] || global.NOT_AVAILABLE;

  return (
    <>
      <UpdateFirmwareModal
        device={activeEnergyPro}
        onClose={() => setShowUpdateFirmwareModal(false)}
        isVisible={showUpdateFirmwareModal}
        firmwareGatewayModel={FirmwareGatewayModel.ENERGYPRO}
      />
      {showDeleteAllDevicesModal && (
        <ConfirmDeleteAllEnergyDevices
          energyPro={activeEnergyPro}
          onClose={() => setShowDeleteAllDevicesModal(false)}
        />
      )}

      {showingDeleteEnergyProModal && (
        <ConfirmDeleteEnergyPro
          energyPro={activeEnergyPro}
          onDelete={() => {
            const energyProsAfterDelete = energyPros.filter(
              item => item.id !== activeEnergyPro.id
            );
            if (energyProsAfterDelete.length) {
              setActiveEnergyProsId(energyProsAfterDelete[0].id);
            }
          }}
        />
      )}
      <Card>
        {isEnergyPro2 && !activeEnergyPro.isCTConfigured && (
          <div className={styles.warningBanner}>
            <WarningIcon size={16} />
            <span className={styles.warningBannerText}>Warning:</span>
            <span>
              <span className={styles.warningBold}>
                Configuration data has not been retrieved from the EnergyPro II.{' '}
              </span>
              If the device is online, click here to request the latest
              configuration from it.
            </span>
            <div
              className={styles.refreshButton}
              onClick={() => {
                reduxDispatch(
                  requestEPro2Config(activeEnergyPro.id)
                  // getEnergyProsByDistributionPanelId(distributionPanel.id)
                );
                setTimerRunning(true);
              }}
            >
              <IoMdRefresh
                size={16}
                className={timerRunning ? 'icon-spin' : ''}
              ></IoMdRefresh>
              <span>
                {timerRunning ? 'Loading...' : 'Request Configuration'}
              </span>
            </div>
          </div>
        )}
        <CardContent>
          <CardTitle className={styles.cardHeader}>
            <div className={styles.selectEnergyPro}>
              <div className={styles.energyProLabel}>
                <div className={styles.energyProHeaderMargin}>
                  {isEnergyPro2 ? (
                    <div style={{ marginTop: -6 }}>
                      <EnergyPro2Icon size={32} />
                    </div>
                  ) : (
                    <EnergyProIcon size={20} />
                  )}
                </div>
                <div className={styles.energyProHeaderMargin}>
                  {eproDisplayText}
                </div>
              </div>
              <div className={styles.energyProHeaderMargin}>
                <SelectActiveEnergyPro
                  energyPros={energyPros}
                  activeEnergyPro={activeEnergyPro}
                  onChange={setActiveEnergyProsId}
                />
              </div>
            </div>
            <MenuDropdown icon={<MdMoreHoriz size={32} strokeWidth={0.1} />}>
              {!isEnergyPro2 && (
                <>
                  <MenuDropdownItem
                    onSelect={() => {
                      setShowUpdateFirmwareModal(true);
                    }}
                  >
                    Update Firmware
                  </MenuDropdownItem>
                  <MenuDropdownItem
                    isRed
                    onSelect={() => {
                      setShowDeleteAllDevicesModal(true);
                    }}
                  >
                    Delete All Energy Devices
                  </MenuDropdownItem>
                </>
              )}

              {isEnergyPro2 && (
                <>
                  <MenuDropdownItem onSelect={() => setActiveOnly(!activeOnly)}>
                    {activeOnly ? 'Show All Ports' : 'Show Only Active Ports'}
                  </MenuDropdownItem>
                  <MenuDropdownItem onSelect={() => {}} disabled>
                    Update CT Polarity/Phase
                  </MenuDropdownItem>
                </>
              )}

              <MenuDropdownItem
                isRed
                onSelect={() => {
                  reduxDispatch(showDeleteEnergyProModal(activeEnergyPro.id));
                }}
              >
                Delete {eproDisplayText}
              </MenuDropdownItem>
            </MenuDropdown>
          </CardTitle>
          <CardTitle className={styles.lastReading}>
            Last Reading:&nbsp;
            <TimeDistance timestamp={subscribedEnergyPro.timestamp} />
          </CardTitle>
          {!isEnergyPro2 ? (
            <>
              <EnergyProConfig
                distributionPanel={distributionPanel}
                siteId={siteId}
                configByDeviceId={localStore}
                energyPro={activeEnergyPro}
                energyProSubscriptionInfo={energyProSubscriptionInfo}
                setUnconfirmedUpdateAction={setUnconfirmedUpdateAction}
                setNewSensorDevice={setNewSensorDevice}
              />
              <SensorBusTree
                busNumber={1}
                title="Sensor Bus 1"
                distributionPanel={distributionPanel}
                nodes={bus1Nodes}
                deviceById={bus1DeviceById}
                siteId={siteId}
                configByDeviceId={localStore}
                energyPro={activeEnergyPro}
                setUnconfirmedUpdateAction={setUnconfirmedUpdateAction}
                localDispatch={localDispatch}
                setNewSensorDevice={setNewSensorDevice}
                showActiveOnly={activeOnly}
              />
              <SensorBusTree
                busNumber={2}
                highlightFromFirstRow={highlightBus2FirstRow}
                title="Sensor Bus 2"
                distributionPanel={distributionPanel}
                nodes={bus2Nodes}
                deviceById={bus2DeviceById}
                siteId={siteId}
                configByDeviceId={localStore}
                energyPro={activeEnergyPro}
                setUnconfirmedUpdateAction={setUnconfirmedUpdateAction}
                localDispatch={localDispatch}
                setNewSensorDevice={setNewSensorDevice}
                showActiveOnly={activeOnly}
              />
            </>
          ) : (
            <>
              <EnergyProConfigGen2
                distributionPanel={distributionPanel}
                siteId={siteId}
                configByDeviceId={localStore}
                energyPro={activeEnergyPro}
                energyProSubscriptionInfo={energyProSubscriptionInfo}
                setUnconfirmedUpdateAction={setUnconfirmedUpdateAction}
                setNewSensorDevice={setNewSensorDevice}
              />
              <SensorBusTreeGen2
                title="Sensor Bus 1"
                distributionPanel={distributionPanel}
                nodes={bus1Nodes}
                deviceById={bus1DeviceById}
                siteId={siteId}
                configByDeviceId={localStore}
                energyPro={activeEnergyPro}
                setUnconfirmedUpdateAction={setUnconfirmedUpdateAction}
                localDispatch={localDispatch}
                showActiveOnly={activeOnly}
              />
              <SensorBusTreeGen2
                title="Sensor Bus 2"
                distributionPanel={distributionPanel}
                nodes={bus2Nodes}
                deviceById={bus2DeviceById}
                siteId={siteId}
                configByDeviceId={localStore}
                energyPro={activeEnergyPro}
                setUnconfirmedUpdateAction={setUnconfirmedUpdateAction}
                localDispatch={localDispatch}
                showActiveOnly={activeOnly}
              />
            </>
          )}
        </CardContent>
      </Card>
      {showingNewEnergyDeviceSensorModal && newSensorDevice && (
        <NewEnergyDeviceSensorModal
          panelId={distributionPanel.id}
          energyDeviceId={newSensorDevice.id}
          sensors={newSensorDevice.sensors}
          refetchEnergyPro
          energyProId={activeEnergyPro.id}
          breakerId={distributionPanel.mainBreaker.id}
        />
      )}
      {unconfirmedUpdateAction && (
        <UpdateModal
          onCancelModal={() => setUnconfirmedUpdateAction(undefined)}
          onConfirmUpdate={onUpdateConfirm}
          modalText={getUpdateConfirmationText(
            unconfirmedUpdateAction,
            activeEnergyPro
          )}
          apiErrorAction={
            EnergyProActions.UPDATE_ENERGY_PRO_CONFIGURATION_ERROR
          }
        />
      )}
    </>
  );
};

const ConfirmDeleteAllEnergyDevices = ({
  energyPro,
  onClose,
}: {
  energyPro: EnergyPro;
  onClose: () => void;
}) => {
  const reduxDispatch = useDispatch();
  const actions = (
    <>
      <Button variant="text" onClick={onClose}>
        Cancel
      </Button>
      <Button
        onClick={() => {
          reduxDispatch(removeAllEnergyDevices(energyPro.id));
          onClose();
        }}
      >
        Remove All
      </Button>
    </>
  );
  return (
    <Modal actions={actions}>
      <p style={{ textAlign: 'center', padding: '1rem' }}>
        Are you sure you want to remove all Energy Devices from{' '}
        <b>{energyPro.title}</b>?
      </p>
    </Modal>
  );
};

const ConfirmDeleteEnergyPro = ({
  energyPro,
  onDelete,
}: {
  energyPro: EnergyPro;
  onDelete: () => void;
}) => {
  const reduxDispatch = useDispatch();

  const editEnergyPro = useSelector(({ energyPros }: ApplicationState) => {
    return energyPros.editById[energyPro.id];
  });

  const actions = (
    <>
      <Button
        variant="text"
        onClick={() => reduxDispatch(hideDeleteEnergyProModal(energyPro.id))}
      >
        Cancel
      </Button>
      <Button
        onClick={() => {
          reduxDispatch(destroy(energyPro.id));
          reduxDispatch(hideDeleteEnergyProModal(energyPro.id));
          onDelete();
        }}
      >
        Delete
      </Button>
    </>
  );

  return (
    <Modal onClose={hideDeleteEnergyProModal} actions={actions}>
      <p style={{ textAlign: 'center' }}>
        Are you sure you want to delete{' '}
        {energyPro ? <b>{energyPro.title}</b> : 'this energy pro'}?
      </p>
      {editEnergyPro &&
        renderAPIerror(
          editEnergyPro.apiError,
          EnergyProActions.DELETE_ENERGY_PRO_ERROR
        )}
    </Modal>
  );
};

const DeleteEnergyDeviceModal = ({
  closeDeleteEnergyDeviceModal,
  onDelete,
  energyDevice,
}) => {
  const actions = (
    <>
      <Button variant="text" onClick={closeDeleteEnergyDeviceModal}>
        Cancel
      </Button>
      <Button onClick={onDelete}>Delete</Button>
    </>
  );

  return (
    <Modal onClose={closeDeleteEnergyDeviceModal} actions={actions}>
      <p style={{ textAlign: 'center' }}>
        Are you sure you want to delete{' '}
        {energyDevice ? <b>{energyDevice.title}</b> : 'this energy device'}?
      </p>
    </Modal>
  );
};

const buildSensorBusNodes = (
  device: EnergyDeviceFromApi | undefined | null,
  resultNodes: EnergyDeviceFromApi[]
) => {
  if (!device) return resultNodes;
  resultNodes.push(device);
  return buildSensorBusNodes(device.busDevice, resultNodes);
};

export type DevicePortDisplayData = EnergySensor & {
  energySensorId: number;
  isMainBreaker: boolean;
  disabled?: boolean;
};

export const useEnergyProStreamDataBySensorId = (id: number) => {
  return useSelector<ApplicationState, ObjectById<EnergyProSensorReading>>(
    ({ subscribedEnergyPros }) => {
      const energyProSensorReadings =
        subscribedEnergyPros.byId[id]?.sensors || [];
      return mapArrayToObject(energyProSensorReadings);
    },
    equals
  );
};

const useSensorsFromEnergyPro = (energyPro: EnergyPro | undefined) => {
  return useMemo(() => {
    const energyDeviceSensors = getEnergyDeviceSensorsOfEnergyPro(energyPro);
    return energyDeviceSensors || [];
  }, [energyPro]);
};

const isPortConnectedToMainBreaker = (
  portNumber: number,
  energySensors: EnergySensor[]
) => {
  const sensor = energySensors.find(s => s.port === portNumber);
  if (sensor && sensor.breaker._entity === 'MainBreaker') {
    return true;
  }
  return false;
};

export const getEnergy = (
  energySensorId: number,
  energySensorsReadingById: ObjectById<EnergyProSensorReading>
) => {
  const sensorReading: EnergyProSensorReading | undefined =
    energySensorsReadingById[energySensorId];
  const energy: number | undefined = sensorReading?.energy;
  return energy !== undefined ? energy : global.NOT_AVAILABLE;
};

export const getPhase = (
  energySensorId: number,
  energySensorsReadingById: ObjectById<EnergyProSensorReading>
) => {
  const sensorReading: EnergyProSensorReading | undefined =
    energySensorsReadingById[energySensorId];
  const phase: number | undefined = sensorReading?.phase;
  return phase !== undefined ? phase : undefined;
};

export const getActivePower = (
  energySensorId: number,
  energySensorsReadingById: ObjectById<EnergyProSensorReading>
) => {
  const sensorReading: EnergyProSensorReading | undefined =
    energySensorsReadingById[energySensorId];
  const activePower: number | undefined = sensorReading?.powerActive;
  return activePower !== undefined ? activePower : global.NOT_AVAILABLE;
};

export const getPowerFactor = (
  energySensorId: number,
  energySensorsReadingById: ObjectById<EnergyProSensorReading>
) => {
  const sensorReading: EnergyProSensorReading | undefined =
    energySensorsReadingById[energySensorId];
  const powerFactor: number | undefined = sensorReading?.powerFactor;
  return powerFactor !== undefined ? powerFactor : global.NOT_AVAILABLE;
};

export const getCurrent = (
  energySensorId: number,
  energySensorsReadingById: ObjectById<EnergyProSensorReading>
) => {
  const sensorReading: EnergyProSensorReading | undefined =
    energySensorsReadingById[energySensorId];
  const current: number | undefined = sensorReading?.current;
  return current !== undefined ? current : global.NOT_AVAILABLE;
};

export default DPSetUpTable;
