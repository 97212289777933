import * as R from 'ramda';

import { ApplicationState } from '../reducers';
const apiBase = '/api/v1/energy-pro';
const dpApiBase = '/api/v1/distribution-panels/energy-pro';
const superHubApiBase = '/api/v1/superHub/admin-portal/energy-pro';
const superHubApiBase2 = '/api/v1/superHub/admin-portal/energy-pro2';
import { Actions as DPActions } from './distribution_panel';

export enum Actions {
  GET_ENERGY_PROS_BY_SITE_ID_SUCCESS = '@energy_pros/GET_ENERGY_PROS_BY_SITE_ID_SUCCESS',
  GET_ENERGY_PROS_BY_SITE_ID_ERROR = '@energy_pros/GET_ENERGY_PROS_BY_SITE_ID_ERROR',
  GET_ENERGY_PROS_BY_SITE_ID_LOADING = '@energy_pros/GET_ENERGY_PROS_BY_SITE_ID_LOADING',

  GET_ENERGY_PRO_SUCCESS = '@energy_pros/GET_ENERGY_PRO_SUCCESS',
  GET_ENERGY_PRO_ERROR = '@energy_pros/GET_ENERGY_PRO_ERROR',
  GET_ENERGY_PRO_LOADING = '@energy_pros/GET_ENERGY_PRO_LOADING',

  APPEND_ENERGY_DEVICE_SUCCESS = '@energy_pros/APPEND_ENERGY_DEVICE_SUCCESS',
  APPEND_ENERGY_DEVICE_ERROR = '@energy_pros/APPEND_ENERGY_DEVICE_ERROR',
  APPEND_ENERGY_DEVICE_LOADING = '@energy_pros/APPEND_ENERGY_DEVICE_LOADING',

  GET_ENERGY_PROS_BY_DISTRIBUTION_PANEL_ID_SUCCESS = '@energy_pros/GET_ENERGY_PROS_BY_DISTRIBUTION_PANEL_ID_SUCCESS',
  GET_ENERGY_PROS_BY_DISTRIBUTION_PANEL_ID_ERROR = '@energy_pros/GET_ENERGY_PROS_BY_DISTRIBUTION_PANEL_ID_ERROR',
  GET_ENERGY_PROS_BY_DISTRIBUTION_PANEL_ID_LOADING = '@energy_pros/GET_ENERGY_PROS_BY_DISTRIBUTION_PANEL_ID_LOADING',

  GET_ENERGY_PRO_BY_UUID_SUCCESS = '@energy_pros/GET_ENERGY_PRO_BY_UUID_SUCCESS',
  GET_ENERGY_PRO_BY_UUID_ERROR = '@energy_pros/GET_ENERGY_PRO_BY_UUID_ERROR',
  GET_ENERGY_PRO_BY_UUID_LOADING = '@energy_pros/GET_ENERGY_PRO_BY_DISTRIBUTION_PANEL_ID_LOADING',

  PATCH_ENERGY_PRO_LOADING = '@energy_pros/PATCH_ENERGY_PRO_LOADING',
  PATCH_ENERGY_PRO_ERROR = '@energy_pros/PATCH_ENERGY_PRO_ERROR',
  PATCH_ENERGY_PRO_SUCCESS = '@energy_pros/PATCH_ENERGY_PRO_SUCCESS',

  DELETE_ENERGY_PRO_SUCCESS = '@energy_pros/DELETE_ENERGY_PRO_SUCCESS',
  DELETE_ENERGY_PRO_ERROR = '@energy_pros/DELETE_ENERGY_PRO_ERROR',
  DELETE_ENERGY_PRO_LOADING = '@energy_pros/DELETE_ENERGY_PRO_LOADING',

  TOGGLE_NEW_ENERGY_PRO_MODAL = '@energy_pros/TOGGLE_NEW_ENERGY_PRO_MODAL',
  CREATE_NEW_ENERGY_PRO = '@energy_pros/CREATE_NEW_ENERGY_PRO',

  TOGGLE_EDIT_ENERGY_PRO_MODAL = '@energy_pros/TOGGLE_EDIT_ENERGY_PRO_MODAL',

  UPDATE_FIELD = '@energy_pros/UPDATE_FIELD',
  RESET_EDIT_ENERGY_PRO = '@energy_pros/RESET_EDIT_ENERGY_PRO',

  CREATE_ENERGY_PRO_LOADING = '@energy_pros/CREATE_ENERGY_PRO_LOADING',
  CREATE_ENERGY_PRO_SUCCESS = '@energy_pros/CREATE_ENERGY_PRO_SUCCESS',
  CREATE_ENERGY_PRO_ERROR = '@energy_pros/CREATE_ENERGY_PRO_ERROR',

  ADD_ENERGY_PRO_SENSOR_TO_PANEL_MAIN_BREAKER_LOADING = '@energy_pros/ADD_ENERGY_PRO_SENSOR_TO_PANEL_MAIN_BREAKER_LOADING',
  ADD_ENERGY_PRO_SENSOR_TO_PANEL_MAIN_BREAKER_SUCCESS = '@energy_pros/ADD_ENERGY_PRO_SENSOR_TO_PANEL_MAIN_BREAKER_SUCCESS',
  ADD_ENERGY_PRO_SENSOR_TO_PANEL_MAIN_BREAKER_ERROR = '@energy_pros/ADD_ENERGY_PRO_SENSOR_TO_PANEL_MAIN_BREAKER_ERROR',

  REMOVE_ENERGY_PRO_SENSOR_FROM_PANEL_MAIN_BREAKER_LOADING = '@energy_pros/REMOVE_ENERGY_PRO_SENSOR_FROM_PANEL_MAIN_BREAKER_LOADING',
  REMOVE_ENERGY_PRO_SENSOR_FROM_PANEL_MAIN_BREAKER_SUCCESS = '@energy_pros/REMOVE_ENERGY_PRO_SENSOR_FROM_PANEL_MAIN_BREAKER_SUCCESS',
  REMOVE_ENERGY_PRO_SENSOR_FROM_PANEL_MAIN_BREAKER_ERROR = '@energy_pros/REMOVE_ENERGY_PRO_SENSOR_FROM_PANEL_MAIN_BREAKER_ERROR',

  REMOVE_DEVICE_FROM_ENERGY_PRO_BUS_LOADING = '@energy_pros/REMOVE_DEVICE_FROM_ENERGY_PRO_BUS_LOADING',
  REMOVE_DEVICE_FROM_ENERGY_PRO_BUS_SUCCESS = '@energy_pros/REMOVE_DEVICE_FROM_ENERGY_PRO_BUS_SUCCESS',
  REMOVE_DEVICE_FROM_ENERGY_PRO_BUS_ERROR = '@energy_pros/REMOVE_DEVICE_FROM_ENERGY_PRO_BUS_ERROR',

  REMOVE_ALL_DEVICES_FROM_ENERGY_PRO_BUS_LOADING = '@energy_pros/REMOVE_ALL_DEVICES_FROM_ENERGY_PRO_BUS_LOADING',
  REMOVE_ALL_DEVICES_FROM_ENERGY_PRO_BUS_SUCCESS = '@energy_pros/REMOVE_ALL_DEVICES_FROM_ENERGY_PRO_BUS_SUCCESS',
  REMOVE_ALL_DEVICES_FROM_ENERGY_PRO_BUS_ERROR = '@energy_pros/REMOVE_ALL_DEVICES_FROM_ENERGY_PRO_BUS_ERROR',

  UPDATE_ENERGY_PRO_CONFIGURATION_LOADING = '@energy_pros/UPDATE_ENERGY_PRO_CONFIGURATION_LOADING',
  UPDATE_ENERGY_PRO_CONFIGURATION_SUCCESS = '@energy_pros/UPDATE_ENERGY_PRO_CONFIGURATION_SUCCESS',
  UPDATE_ENERGY_PRO_CONFIGURATION_ERROR = '@energy_pros/UPDATE_ENERGY_PRO_CONFIGURATION_ERROR',

  TOGGLE_REMOVE_ALL_ENERGY_DEVICES_FROM_BUS_MODAL = '@energy_pros/TOGGLE_REMOVE_ALL_ENERGY_DEVICES_FROM_BUS_MODAL',
  TOGGLE_DELETE_ENERGY_PRO_MODAL = '@energy_pros/TOGGLE_DELETE_ENERGY_PRO_MODAL',
  TOGGLE_UPDATE_ENERGY_PRO_CONFIGURATION_MODAL = '@energy_pros/TOGGLE_UPDATE_ENERGY_PRO_CONFIGURATION_MODAL',

  PRIME_FOR_DELETE = '@energy_pros/PRIME_FOR_DELETE',
  UNSTAGE_DELETE = '@energy_pros/UNSTAGE_DELETE',

  DISPLAY_FORM_ERRORS = '@energy_pros/DISPLAY_FORM_ERRORS',

  LOOKUP_ENERGY_PRO_SUCCESS = '@energy_pros/LOOKUP_ENERGY_PRO_SUCCESS',
  LOOKUP_ENERGY_PRO_ERROR = '@energy_pros/LOOKUP_ENERGY_PRO_ERROR',
  LOOKUP_ENERGY_PRO_LOADING = '@energy_pros/LOOKUP_ENERGY_PRO_LOADING',

  REQUEST_EPRO2_CONFIG_SUCCESS = '@energy_pros/REQUEST_EPRO2_CONFIG_SUCCESS',
  REQUEST_EPRO2_CONFIG_ERROR = '@energy_pros/REQUEST_EPRO2_CONFIG_ERROR',
  REQUEST_EPRO2_CONFIG_LOADING = '@energy_pros/REQUEST_EPRO2_CONFIG_LOADING',
}

export type GetEnergyProParams = {
  distributionPanelIds?: number[];
  ids?: string[] | number[];
  limit?: number;
  siblings?: boolean;
  siteIds?: number[];
  skip?: number;
  uuids?: string[];
};

const createQueryString = (params: GetEnergyProParams = {}) => {
  return Object.keys(params)
    .map(
      key =>
        `${key}=${
          Array.isArray(params[key]) ? params[key].join(',') : params[key]
        }`
    )
    .join('&');
};

export const getEnergyPro = (id: string | number) => ({
  type: 'API_GET',
  path: `${apiBase}/${id}`,
  success: Actions.GET_ENERGY_PRO_SUCCESS,
  error: Actions.GET_ENERGY_PRO_ERROR,
  loading: Actions.GET_ENERGY_PRO_LOADING,
});

export const getEnergyProsBySiteId = (siteId: number | string) => ({
  type: 'API_GET',
  path: `${apiBase}/site/${siteId}`,
  success: { type: Actions.GET_ENERGY_PROS_BY_SITE_ID_SUCCESS, siteId },
  error: { type: Actions.GET_ENERGY_PROS_BY_SITE_ID_ERROR, siteId },
  loading: { type: Actions.GET_ENERGY_PROS_BY_SITE_ID_LOADING, siteId },
});

export const getEnergyProsByDistributionPanelId = (
  distributionPanelId: number
) => ({
  type: 'API_GET',
  path: `${apiBase}/distribution-panel/${distributionPanelId}`,
  success: {
    type: Actions.GET_ENERGY_PROS_BY_DISTRIBUTION_PANEL_ID_SUCCESS,
    id: distributionPanelId,
  },
  error: Actions.GET_ENERGY_PROS_BY_DISTRIBUTION_PANEL_ID_ERROR,
  loading: Actions.GET_ENERGY_PROS_BY_DISTRIBUTION_PANEL_ID_LOADING,
});

export const getEnergyProByUuid = (uuid: string) => ({
  type: 'API_GET',
  path: `${apiBase}/lookup/${uuid}`,
  success: Actions.GET_ENERGY_PRO_BY_UUID_SUCCESS,
  error: Actions.GET_ENERGY_PRO_BY_UUID_ERROR,
  loading: Actions.GET_ENERGY_PRO_BY_UUID_LOADING,
});

export const create = (distributionPanelId: number) => (dispatch, getState) => {
  dispatch({
    type: 'API_POST',
    path: `${apiBase}`,
    payload: {
      ...(getState() as ApplicationState).energyPros.editById['new'].fields,
      distributionPanelId,
    },
    loading: Actions.CREATE_ENERGY_PRO_LOADING,
    success: Actions.CREATE_ENERGY_PRO_SUCCESS,
    error: Actions.CREATE_ENERGY_PRO_ERROR,
  });
};

export const createWithDistributionPanel = () => (dispatch, getState) => {
  const dpFields = (getState() as ApplicationState).distributionPanels.editById[
    'new'
  ].fields;
  const energyProFields = (getState() as ApplicationState).energyPros.editById[
    'new'
  ].fields;

  // TODO: This is a temporary solution to set the breaker slots for the DP
  // based on the EnergyPro model. This will be removed once the new UI is
  // implemented.
  if (energyProFields.energyProModel === 'ENERGY_PRO2') {
    dpFields.breakerSlots = 24;
  }

  dispatch({
    type: 'API_POST',
    path: `${dpApiBase}`,
    payload: {
      distributionPanel: dpFields,
      energyPro: energyProFields,
    },
    loading: DPActions.CREATE_DISTRIBUTION_PANEL_LOADING,
    success: DPActions.CREATE_DISTRIBUTION_PANEL_SUCCESS,
    error: DPActions.CREATE_DISTRIBUTION_PANEL_ERROR,
  });
};

export const patch = (id: string) => (dispatch, getState) => {
  const patchableFields = [
    'description',
    'identifier',
    'sampleFrequency',
    'title',
    'uuid',
    'acPowerSystem',
    'poweredFromDistributionPanel',
    'networkGroupId',
  ];

  dispatch({
    type: 'API_PATCH',
    path: `${apiBase}/${id}`,
    payload: R.pick(
      patchableFields,
      (getState() as ApplicationState).energyPros.editById[id].fields
    ),
    loading: { type: Actions.PATCH_ENERGY_PRO_LOADING, id },
    success: { type: Actions.PATCH_ENERGY_PRO_SUCCESS, id },
    error: { type: Actions.PATCH_ENERGY_PRO_ERROR, id },
  });
};

export const addEnergyProSensorToPanelMainBreaker = (
  energyProId: string,
  refetchEnergyPro?: boolean
) => (dispatch, getState) => {
  const fields = (getState() as ApplicationState).energyDevices
    .editEnergyDeviceSensorsByEnergyDeviceId[energyProId]['new'].fields;
  const payload = {
    ct: fields.ct,
    energyProPort: fields.energyDevicePort,
    mainBreakerPole: fields.breakerPole,
    phase: fields.phase,
    reversePolarity: fields.reversePolarity,
  };

  dispatch({
    type: 'API_POST',
    path: `${apiBase}/${energyProId}/sensor`,
    payload: payload,
    loading: {
      type: Actions.ADD_ENERGY_PRO_SENSOR_TO_PANEL_MAIN_BREAKER_LOADING,
    },
    success: refetchEnergyPro
      ? [
          {
            type: Actions.ADD_ENERGY_PRO_SENSOR_TO_PANEL_MAIN_BREAKER_SUCCESS,
          },
          getEnergyPro(energyProId),
        ]
      : {
          type: Actions.ADD_ENERGY_PRO_SENSOR_TO_PANEL_MAIN_BREAKER_SUCCESS,
        },
    error: { type: Actions.ADD_ENERGY_PRO_SENSOR_TO_PANEL_MAIN_BREAKER_ERROR },
  });
};

export const removeEnergyProSensorFromPanelMainBreaker = (
  energyDeviceId: string | number,
  port: number
) => (dispatch, getState) => {
  dispatch({
    type: 'API_DELETE',
    path: `${apiBase}/${energyDeviceId}/sensor/${port}`,
    loading: {
      type: Actions.REMOVE_ENERGY_PRO_SENSOR_FROM_PANEL_MAIN_BREAKER_LOADING,
      energyDeviceId,
      port,
    },
    success: {
      type: Actions.REMOVE_ENERGY_PRO_SENSOR_FROM_PANEL_MAIN_BREAKER_SUCCESS,
      energyDeviceId,
      port,
    },
    error: {
      type: Actions.REMOVE_ENERGY_PRO_SENSOR_FROM_PANEL_MAIN_BREAKER_ERROR,
      energyDeviceId,
      port,
    },
  });
};

export const appendEnergyDevice = (id: string, bus: number) => (
  dispatch,
  getState
) => {
  const acceptedAppendEnergyDeviceFields = ['title', 'description', 'model'];

  dispatch({
    type: 'API_POST',
    path: `${apiBase}/${id}/energy-device/${bus}`,
    payload: R.pick(
      acceptedAppendEnergyDeviceFields,
      (getState() as ApplicationState).energyDevices['editById']['new'].fields
    ),
    loading: { type: Actions.APPEND_ENERGY_DEVICE_LOADING, id },
    success: { type: Actions.APPEND_ENERGY_DEVICE_SUCCESS, id },
    error: { type: Actions.APPEND_ENERGY_DEVICE_ERROR, id },
  });
};

export const destroy = (id: string | number, redirectAfter?: boolean) => ({
  type: 'API_DELETE',
  path: `${apiBase}/${id}`,
  loading: { type: Actions.DELETE_ENERGY_PRO_LOADING, id, redirectAfter },
  success: { type: Actions.DELETE_ENERGY_PRO_SUCCESS, id, redirectAfter },
  error: { type: Actions.DELETE_ENERGY_PRO_ERROR, id, redirectAfter },
});

export const removeEnergyDeviceFromBus = (
  energyProId: string | number,
  deviceId: string | number,
  bus: number
) => ({
  type: 'API_DELETE',
  path: `${apiBase}/${energyProId}/energy-device/${bus}`,
  loading: {
    type: Actions.REMOVE_DEVICE_FROM_ENERGY_PRO_BUS_LOADING,
    energyProId,
    bus,
    deviceId,
  },
  success: {
    type: Actions.REMOVE_DEVICE_FROM_ENERGY_PRO_BUS_SUCCESS,
    energyProId,
    deviceId,
  },
  error: {
    type: Actions.REMOVE_DEVICE_FROM_ENERGY_PRO_BUS_ERROR,
    energyProId,
    bus,
    deviceId,
  },
});

export const removeAllEnergyDeviceFromBus = (
  id: string | number,
  bus: number
) => ({
  type: 'API_DELETE',
  path: `${apiBase}/${id}/energy-devices/${bus}`,
  loading: { type: Actions.REMOVE_ALL_DEVICES_FROM_ENERGY_PRO_BUS_LOADING, id },
  success: { type: Actions.REMOVE_ALL_DEVICES_FROM_ENERGY_PRO_BUS_SUCCESS, id },
  error: { type: Actions.REMOVE_ALL_DEVICES_FROM_ENERGY_PRO_BUS_ERROR, id },
});

export const removeAllEnergyDevices = (id: string | number) => ({
  type: 'API_DELETE',
  path: `${apiBase}/${id}/energy-devices/1`,
  loading: { type: Actions.REMOVE_ALL_DEVICES_FROM_ENERGY_PRO_BUS_LOADING, id },
  success: {
    type: 'API_DELETE',
    path: `${apiBase}/${id}/energy-devices/2`,
    success: [
      { type: Actions.REMOVE_ALL_DEVICES_FROM_ENERGY_PRO_BUS_SUCCESS, id },
      getEnergyPro(id),
    ],
    error: { type: Actions.REMOVE_ALL_DEVICES_FROM_ENERGY_PRO_BUS_ERROR, id },
  },
  error: { type: Actions.REMOVE_ALL_DEVICES_FROM_ENERGY_PRO_BUS_ERROR, id },
});

export const reset = (id: string) => ({
  type: Actions.RESET_EDIT_ENERGY_PRO,
  id,
});

export const showNewEnergyProModal = () => ({
  type: Actions.TOGGLE_NEW_ENERGY_PRO_MODAL,
  value: true,
});

export const hideNewEnergyProModal = () => ({
  type: Actions.TOGGLE_NEW_ENERGY_PRO_MODAL,
  value: false,
});

export const showEditEnergyProModal = (id: string) => ({
  type: Actions.TOGGLE_EDIT_ENERGY_PRO_MODAL,
  id,
  value: true,
});

export const hideEditEnergyProModal = () => ({
  type: Actions.TOGGLE_EDIT_ENERGY_PRO_MODAL,
  value: false,
});

export const displayFormErrors = (id: string) => ({
  type: Actions.DISPLAY_FORM_ERRORS,
  value: true,
  id,
});

export const showDeleteEnergyProModal = (id: string | number) => ({
  type: Actions.TOGGLE_DELETE_ENERGY_PRO_MODAL,
  id,
  value: true,
});

export const hideDeleteEnergyProModal = (id: string | number) => ({
  type: Actions.TOGGLE_DELETE_ENERGY_PRO_MODAL,
  id,
  value: false,
});

export const showRemoveAllEnergyDevicesFromBusModal = (id: string) => ({
  type: Actions.TOGGLE_REMOVE_ALL_ENERGY_DEVICES_FROM_BUS_MODAL,
  id,
  value: true,
});

export const hideRemoveAllEnergyDevicesFromBusModal = (id: string) => ({
  type: Actions.TOGGLE_REMOVE_ALL_ENERGY_DEVICES_FROM_BUS_MODAL,
  id,
  value: false,
});

export const primeForDelete = (id: string) => ({
  type: Actions.PRIME_FOR_DELETE,
  id,
});

export const unstageDelete = () => ({
  type: Actions.UNSTAGE_DELETE,
});

export const updateField = (id: string, field: string, value: any) => ({
  type: Actions.UPDATE_FIELD,
  id,
  field,
  value,
});

export const updateEnergyProConfiguration = (id: string) => ({
  type: 'API_GET',
  path: `${apiBase}/${id}/sensors-config`,
  success: [
    {
      type: Actions.UPDATE_ENERGY_PRO_CONFIGURATION_SUCCESS,
      id,
    },
    hideUpdateEnergyProConfigModal(id),
  ],
  error: {
    type: Actions.UPDATE_ENERGY_PRO_CONFIGURATION_ERROR,
    id,
  },
  loading: {
    type: Actions.UPDATE_ENERGY_PRO_CONFIGURATION_LOADING,
    id,
  },
});

export const showUpdateEnergyProConfigModal = (id: string | number) => ({
  type: Actions.TOGGLE_UPDATE_ENERGY_PRO_CONFIGURATION_MODAL,
  id,
  value: true,
});

export const hideUpdateEnergyProConfigModal = (id: string | number) => ({
  type: Actions.TOGGLE_UPDATE_ENERGY_PRO_CONFIGURATION_MODAL,
  id,
  value: false,
});

export const createNewEnergyPro = () => ({
  type: Actions.CREATE_NEW_ENERGY_PRO,
});

export const lookupEnergyPro = (
  model: 'ENERGY_PRO1' | 'ENERGY_PRO2',
  serialNumber: string,
  siteId: number
) => ({
  type: 'API_GET',
  path: `${superHubApiBase}/lookup?model=${model}&serialNumber=${serialNumber}&siteId=${siteId}`,
  success: Actions.LOOKUP_ENERGY_PRO_SUCCESS,
  error: Actions.LOOKUP_ENERGY_PRO_ERROR,
  loading: Actions.LOOKUP_ENERGY_PRO_LOADING,
});

export const requestEPro2Config = (id: number | string) => ({
  type: 'API_GET',
  path: `${superHubApiBase2}/${id}/config`,
  success: Actions.REQUEST_EPRO2_CONFIG_SUCCESS,
  error: Actions.REQUEST_EPRO2_CONFIG_ERROR,
  loading: Actions.REQUEST_EPRO2_CONFIG_LOADING,
});
